import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useHttp from "../hooks/use-http";
import Loading from "./Loading";

const Category = () => {
  const { isLoading, error, sendRequest: httpRequest } = useHttp();

  //   useEffect(() => {
  //     fetch();
  //   });

  const fetch = async () => {
    console.log("ok");
    httpRequest(
      {
        url: "https://sandbox.cou.setu.co/api/bills/uat/biller-categories",
        method: "GET",
        headers: {
          "axis-channel-id": "FORMAX",
          "axis-client-id": "da8211dd-7195-40fc-8eb3-73a637a2be45",
          "axis-client-secret": "9178ec03-7248-4016-91bc-18bc4557fa50",
          "axis-encryption-key": "axisbankaxisbank",
          "axis-salt-key": "axisbankaxisbank",
          "axis-channel-password": "119bd807-e810-4312-a0b3-c984beb95886",
          "axis-body-channel-id": "686",
          "cache-control": "no-cache",
          "postman-token": "c15d30f3-05b0-e120-c53d-6466e759ef4e",
        },
      },
      transformData
    );
  };

  const transformData = (Obj) => {
    console.log("from transform data");
  };

  return (
    <div>
      {isLoading && <Loading />}
      <span>{error}</span>
      <Autocomplete
        id="combo-box-demo"
        options={top100Films}
        getOptionLabel={(option) => option.title}
        style={{ width: 300 }}
        renderInput={(params) => (
          <TextField {...params} label="Category" variant="outlined" />
        )}
      />
      <Button onClick={fetch}>Fetch</Button>
    </div>
  );
};

const top100Films = [{ title: "The Shawshank Redemption", year: 1994 }];

export default Category;
