import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import PhoneInTalkRoundedIcon from "@material-ui/icons/PhoneInTalkRounded";
import EmailIcon from "@material-ui/icons/Email";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({}));

const Header = () => {
  const classes = useStyles();
  return (
    <AppBar position="fixed" color="default">
      <Toolbar>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <img src="logo.png" alt="logo" width="150px" />
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                size="medium"
                className={classes.button}
                startIcon={<PhoneInTalkRoundedIcon />}
                mt={1}
              >
                +91 9073-196-444
              </Button>

              <Button
                size="medium"
                className={classes.button}
                startIcon={<EmailIcon />}
                style={{ textTransform: "lowercase" }}
              >
                helpdesk@formaxpay.com
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
