import React from "react";
import Header from "./components/Header";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Category from "./components/Category";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function App() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header />
      <Box pt={10} />
      <Category />
    </div>
  );
}
